import { environment } from '@environments/environment';
import * as Sentry from '@sentry/angular';

export const SENTRY_UUID_LOCALSTORAGE_KEY = 'sentry-machine-uuid';

export const SentryErrorHandler = Sentry.createErrorHandler({
  showDialog: false,
});

export const initializeSentry = () => {
  if (environment.sentry.enabled) {
    Sentry.init({
      environment: environment.sentry.environment,
      dsn: environment.sentry.dsn,
      ignoreErrors: ['Object captured as exception'],
      integrations: [
        // Registers and configures the Tracing integration,
        // which automatically instruments your application to monitor its
        // performance, including custom Angular routing instrumentation
        Sentry.browserTracingIntegration(),
      ],
      tracesSampleRate: 0,
    });
    setSentryUser();
  }
};

/**
 * Generates a UUID that is stored in localStorage and used as a proxy for an
 * individual provider. If an existing value is found, uses that instead.
 *
 * This UUID is used in place of a proper user identifier so that we can determine
 * the blast radius of sentries without risking provider info being pushed outside of
 * our systems.
 *
 * @returns the user data supplied to sentry, for introspection
 */
export function setSentryUser(): Sentry.User | undefined {
  if (localStorageSupported()) {
    let machineUUID = localStorage.getItem(SENTRY_UUID_LOCALSTORAGE_KEY);
    if (!machineUUID) {
      machineUUID = crypto.randomUUID();
      localStorage.setItem(SENTRY_UUID_LOCALSTORAGE_KEY, machineUUID);
    }

    const sentryUser = {
      id: machineUUID,
    };
    Sentry.setUser(sentryUser);

    return sentryUser;
  }
}

// Checks to see if local storage is supported. In some browsers like Safari, localStorage does not work
// by default.
function localStorageSupported(): boolean {
  try {
    localStorage.setItem('FAKEKEY', 'WOW');
    localStorage.removeItem('FAKEKEY');
    return true;
  } catch (_) {
    return false;
  }
}
