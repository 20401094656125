import { StateActions } from '@app/core/store/shared/state';
import { Action } from '@ngrx/store';
import { SearchCriteria } from '@app/shared/models/search-criteria';
import { ResultSet } from '@app/shared/models/result-set';
import { Template } from '@app/shared/models/template';

export enum TextTemplateActionTypes {
  SEARCH = 'Snippet Search',
  SEARCH_SUCCESS = 'Snippet Search Success',
  SEARCH_ERROR = 'Snippet Search Error',
  PAGINATE = 'Snippet Paginate',
  PAGINATE_SUCCESS = 'Snippet Paginate Success',
  PAGINATE_ERROR = 'Snippet Paginate Error',
}

export class Search implements Action {
  readonly type = TextTemplateActionTypes.SEARCH;
  constructor(public payload: SearchCriteria) {}
}

export class SearchSuccess implements Action {
  readonly type = TextTemplateActionTypes.SEARCH_SUCCESS;
  constructor(public payload: ResultSet<Template>) {}
}

export class SearchError implements Action {
  readonly type = TextTemplateActionTypes.SEARCH_ERROR;
  constructor(public payload: any) {}
}

export class Paginate implements Action {
  readonly type = TextTemplateActionTypes.PAGINATE;
  constructor(public payload: SearchCriteria) {}
}

export class PaginateSuccess implements Action {
  readonly type = TextTemplateActionTypes.PAGINATE_SUCCESS;
  constructor(public payload: ResultSet<Template>) {}
}

export type TextTemplateCustomAction =
  Search |
  SearchSuccess |
  Paginate |
  PaginateSuccess |
  SearchError;

export class TextTemplatesActions extends StateActions<Template> {

  search(payload: SearchCriteria): void {
    this.store.dispatch(new Search(payload));
  }

  paginate(payload: SearchCriteria): void {
    this.store.dispatch(new Paginate(payload));
  }

  searchSuccess(payload: ResultSet<Template>): void {
    this.store.dispatch(new SearchSuccess(payload));
  }

  paginateSuccess(payload: ResultSet<Template>): void {
    this.store.dispatch(new PaginateSuccess(payload));
  }
}
