import { Injectable } from '@angular/core';
import { createSelector, select, Store } from '@ngrx/store';

import { AppState } from '../store/app-store.reducer';
import { RouterState, routerStateKey } from './router.reducer';

const getState = state => state[routerStateKey] as RouterState;

export const getCurrentUrl = createSelector(
  getState,
  (state: RouterState) => state.state && state.state.url,
);

@Injectable()
export class RouterSelectors {
  constructor(private store: Store<AppState>) { }

  currentUrl = () => this.store.pipe(select(getCurrentUrl));
}
