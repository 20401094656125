<div class="ng-value-container">
    <a class="badge"
       *ngFor="let tag of templateTags | slice:0:truncateAfter"
       (click)="tagClick.emit(tag)">
        <span class="badge-label">
            {{ tag }}
        </span>
    </a>
    <label class="truncate-tag-label"
           *ngIf="templateTags.length > truncateAfter">
        +{{templateTags.length - truncateAfter}}
    </label>
</div>
