<header>
  <omg-app-navbar></omg-app-navbar>
</header>

<main class="page-content-container container">
  <div class="title-container d-flex flex-row justify-content-between">
    <h3 class="page-title">Template Manager</h3>
    <div
      class="btn-group position-relative"
      role="group"
      ngbDropdown
      placement="bottom-right"
    >
      <button
        type="button"
        class="om-button -primary -slim"
        om-layout-align="space-between center"
        ngbDropdownToggle
        data-cy="create-template-button"
      >
        + Create New
      </button>
      <div class="dropdown-menu" ngbDropdownMenu>
        <a
          class="dropdown-item"
          ngbDropdownItem
          type="button"
          (click)="openCreateTextTemplate()"
          data-create-type="text"
          >Snippet</a
        >
        <a
          class="dropdown-item"
          ngbDropdownItem
          type="button"
          (click)="openCreateMessageTemplate()"
          data-create-type="message"
          >Messages</a
        >
      </div>
    </div>
  </div>

  <ul class="tabs">
    <li [class.active]="tab === 'snippet'">
      <a routerLink="/templates/snippet"> Snippets </a>
    </li>
    <li [class.active]="tab === 'message'">
      <a routerLink="/templates/message"> Messages </a>
    </li>
  </ul>

  <router-outlet></router-outlet>
</main>
