<ngb-toast #toast *ngFor="let message of messages$ | async" [autohide]="true" [delay]="message.delay || 5000"
  (hidden)="closeMessage(message)" [className]="message.severity">
  <div>
    <fa-icon class="ui-toast-icon" [icon]="getIcon(message.severity)"></fa-icon>

    <div class="d-flex flex-column ui-toast-message-text-content">
      <div class="ui-toast-summary" *ngIf="message.summary" [innerHTML]="message.summary"></div>
      <div class="d-flex flex-column ui-toast-detail" *ngIf="message.detail">
        <div class="ui-toast-detail-body" [innerHTML]="message.detail"></div>
        <a [routerLink]="message.data?.routerLink"
          *ngIf="message.data?.routerLink && message.data?.linkText">{{message.data.linkText}}</a>
      </div>
    </div>
  </div>
  <fa-icon class="ui-toast-close-icon" [icon]="closeIcon" (click)="toast.hide()"></fa-icon>
</ngb-toast>
