import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { MessageTemplatesComponent } from './components/message-templates/message-templates.component';
import { MessageTemplatesStoreModule } from './store/message-templates-store.module';
import { TagModule } from '@app/features/tag/tag.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    SharedModule,
    MessageTemplatesStoreModule,
    NgbModule,
    TagModule,
  ],
  exports: [
    MessageTemplatesComponent,
  ],
  declarations: [
    MessageTemplatesComponent,
  ]
})
export class MessageTemplatesModule { }
