<form class="search-form"
      [formGroup]="formGroup">

    <div class="search-field free-text-field">
        <fa-icon class="search-icon"
                 [icon]="faSearch">
        </fa-icon>
        <input class="form-control"
               type="text"
               placeholder="Search for a template"
               formControlName="searchTerm"
               data-cy="search-term-field">
        <button class="btn btn-sm clear-button"
                *ngIf="hasSearchTerm()"
                type="button"
                (click)="clearSearch()">
            <fa-icon class="clear-icon"
                     [icon]="faTimes">
            </fa-icon>
        </button>
    </div>

    <ng-select class="search-field"
               #ownerSelect
               labelForId="owner"
               [searchable]="false"
               [clearable]="false"
               formControlName="purpose"
               data-cy="purpose-select-field">
        <ng-option value="all">All</ng-option>
        <ng-option value="public">Public</ng-option>
        <ng-option value="personal">Personal</ng-option>
        <ng-option *ngIf="context.type === 'message'"
                   value="auto_labs">Auto-Labs</ng-option>
    </ng-select>

    <omg-tags-select-field class="search-field tags-field"
                           [control]="tagsControl">
    </omg-tags-select-field>
</form>

<omg-pagination (prev)="onPagination($event)"
                (next)="onPagination($event)">
</omg-pagination>
