import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ApiService } from '@app/core';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';

import { TextTemplatesApiService } from '../shared/text-templates-api.service';
import { TextTemplatesActions } from './text-templates.actions';
import { TextTemplatesEffects } from './text-templates.effects';
import {
  textTemplatesActionNameTypeMap,
  TextTemplatesReducerToken,
  textTemplatesStateConfig,
  textTemplatesReducerFactory
} from './text-templates.reducer';
import { TextTemplatesSelectors } from './text-templates.selectors';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      textTemplatesStateConfig.statePath,
      TextTemplatesReducerToken
    ),
    EffectsModule.forFeature([TextTemplatesEffects])
  ],
  providers: [
    {
      provide: TextTemplatesReducerToken,
      useFactory: textTemplatesReducerFactory
    },
    {
      provide: TextTemplatesSelectors,
      useFactory: store =>
        new TextTemplatesSelectors(store),
      deps: [Store]
    },
    {
      provide: TextTemplatesActions,
      useFactory: store =>
        new TextTemplatesActions(store, textTemplatesActionNameTypeMap),
      deps: [Store]
    },
    {
      provide: TextTemplatesApiService,
      useFactory: apiService => new TextTemplatesApiService(apiService),
      deps: [ApiService]
    }
  ]
})
export class TextTemplatesStoreModule { }
