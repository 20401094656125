import { Resolve } from '@angular/router';
import { Profile } from './profile.type';
import { Observable, noop } from 'rxjs';
import { ProfileActions } from '../store/profile.actions';
import { ProfileSelectors } from '../store/profile.selectors';
import { tap, first } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class UserProfileResolver implements Resolve<Profile> {

  constructor(private actions: ProfileActions, private selectors: ProfileSelectors) { }

  resolve(): Observable<Profile> {
    // fetch the profile from the store.
    // if the profile doesnt exist in the store, fire action to retrieve it from the api.
    // resolve once the profile actually exists in the store.
    return this
      .selectors
      .profile
      .pipe(
        tap(profile => profile ? noop() : this.actions.getProfile()),
        first(profile => profile != null)
      );
  }
}
