import { Component, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  faCheck,
  faExclamationTriangle,
  faInfoCircle,
  faTimes,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { Actions } from '@ngrx/effects';
import { merge, Observable, Subject } from 'rxjs';
import { scan } from 'rxjs/operators';

import { ToastMessage, ToastSeverity } from './toast-message.interface';
import {
  templateErrorToasts$,
  templateSuccessToasts$,
} from './toast-message.utils';

@Component({
  selector: 'omg-toast-message',
  templateUrl: './toast-message.component.html',
  styleUrls: ['./toast-message.component.scss'],
})
export class ToastMessageComponent {
  private closeMessageSub$ = new Subject<ToastMessage>();
  private closedMessage$ = this.closeMessageSub$.asObservable();

  messages$: Observable<ToastMessage[]> = merge(
    templateSuccessToasts$(this.actions$),
    templateErrorToasts$(this.actions$),
    this.closedMessage$
  ).pipe(
    scan<ToastMessage, ToastMessage[]>(
      (acc, message) =>
        acc.includes(message)
          ? acc.filter((m) => m !== message)
          : [...acc, this.sanitize(message)],
      []
    )
  );

  closeIcon: IconDefinition = faTimes;

  constructor(private actions$: Actions, private sanitizer: DomSanitizer) {}

  closeMessage(message: ToastMessage): void {
    this.closeMessageSub$.next(message);
  }

  getIcon(severity: ToastSeverity): IconDefinition {
    switch (severity) {
    case 'warn':
    case 'error':
      return faExclamationTriangle;
    case 'success':
      return faCheck;
    case 'info':
    default:
      return faInfoCircle;
    }
  }

  private sanitize(message: ToastMessage): ToastMessage {
    return {
      ...message,
      detail: this.sanitizeHtml(message.detail),
      summary: this.sanitizeHtml(message.summary),
    };
  }

  private sanitizeHtml(html: string): string {
    return html ? this.sanitizer.sanitize(SecurityContext.HTML, html) : '';
  }
}
