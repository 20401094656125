import { Subscription } from 'rxjs';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Tag } from '@app/features/tag/shared/tag.type';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { TemplateService } from '@app/shared/services/template.service';
import { Template } from '@app/shared/models/template';
import { NgSelectSearchEvent } from '@app/shared/models/select-search-event';
import { Profile } from '@app/core/profile';
import { Role } from '@app/shared/models/role';
import { TemplateContextToken } from '@app/shared/tokens/template-context.token';
import { TemplateContext } from '@app/shared/models/template-context';

type Mode = 'create' | 'edit' | 'view';
type ToolbarItem = string | { list: string };
interface QuillModules {
  toolbar: false | ToolbarItem[];
}

@Component({
  selector: 'omg-template-modal',
  templateUrl: './template-modal.component.html',
  styleUrls: ['./template-modal.component.scss'],
})
export class TemplateModalComponent implements OnInit {

  @Input() mode: Mode;
  @Input() template: Template;
  @Input() user: Profile;
  @Input() tags: Tag[];

  form: UntypedFormGroup;
  tagDropdownIsOpen = false;
  valueChangeSubscription: Subscription;
  nameControl: AbstractControl;
  tagsControl: AbstractControl;
  bodyControl: AbstractControl;
  autoLabsRadioOptionVisible: boolean;
  quillModules: QuillModules;
  quillFormat: string;

  constructor(
    @Inject(TemplateContextToken) public context: TemplateContext,
    public modal: NgbActiveModal,
    public templateService: TemplateService,
  ) {}

  ngOnInit(): void {
    const templateEditor = this.user.roles.includes(Role.TemplateEditor);
    const automatedTemplateEditor = this.user.roles.includes(Role.AutomatedTemplateEditor);
    const purpose = this.templateService.purpose(this.template);
    const purposeEnabled = templateEditor || automatedTemplateEditor;
    const tagIds = (this.template.tags as Tag[]).map(tag => tag.id);
    this.nameControl = new UntypedFormControl(this.template.name, Validators.required);
    this.tagsControl = new UntypedFormControl(tagIds);
    this.bodyControl = new UntypedFormControl(this.template.body, Validators.required);
    this.autoLabsRadioOptionVisible = automatedTemplateEditor && this.context.type === 'message';
    this.form = new UntypedFormGroup({
      name: this.nameControl,
      body: this.bodyControl,
      selectedTags: this.tagsControl,
      purpose: new UntypedFormControl({
        value: purpose,
        disabled: !purposeEnabled,
      }),
    });
    this.initQuillModules();
  }

  onSearch(event: NgSelectSearchEvent): void {
    this.tagDropdownIsOpen = event.term.length >= 2;
  }

  closeDropdown(): void {
    this.tagDropdownIsOpen = false;
  }

  doSave(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      const { body, name, selectedTags } = this.form.value;
      const tags: Tag[] = selectedTags.map(id => this.idAsTag(id));
      const purpose = this.form.value.purpose ? this.form.value.purpose : 'personal';
      const internalUserId = purpose === 'personal' ? this.user.id : null;
      if (this.mode === 'edit') {
        this.context.actions.update({
          id: this.template.id,
          changes: { body, name, tags, internalUserId, purpose },
        });
      } else if (this.mode === 'create') {
        this.context.actions.create({ body, name, tags, internalUserId, purpose });
      }
      this.modal.close();
    }
  }

  private initQuillModules(): void {
    if (this.context.type == 'text') {
      this.quillFormat = 'text';
      this.quillModules = {
        toolbar: false
      };
    } else {
      this.quillFormat = 'html';
      this.quillModules = {
        toolbar: [
          'bold',
          { list: 'ordered' },
          { list: 'bullet' },
          'link'
        ],
      };
    }
  }

  private idAsTag(id: number): Partial<Tag> {
    return {id};
  }
}
