<nav>

  <div class="primary">
    <a class="menu-item"
       [href]="urls.admin">
      <img class="logo"
           src="assets/om-atom.svg"
           alt="One Medical">
    </a>
    <a class="menu-item text-item"
       [href]="urls.tasks"
       data-cy="navbar-tasks-button">
      Tasks
    </a>
    <div ngbDropdown>
      <a ngbDropdownToggle
         class="menu-item text-item"
         data-cy="navbar-appointments-dropdown">
        Appointments
      </a>
      <div ngbDropdownMenu>
        <a ngbDropdownItem
           [href]="appointmentsUrl$ | async"
           data-cy="navbar-appointments-appointments-item">
          Appointments
        </a>
        <a ngbDropdownItem
           [href]="urls.appointmentScanner"
           data-cy="navbar-appointments-scanner-item">
          Appointment Scanner
        </a>
        <a ngbDropdownItem
           [href]="providerScheduleUrl$ | async"
           data-cy="navbar-appointments-provider-item">
          Provider Schedule
        </a>
        <a ngbDropdownItem
           [href]="walkInVisitsUrl$ | async"
           data-cy="navbar-appointments-walkins-item">
          Walk In Visits
        </a>
        <a ngbDropdownItem
           [href]="urls.unregisteredPatients"
           data-cy="navbar-appointments-unregistered-item">
          Unregistered Patients
        </a>
        <a ngbDropdownItem
           [href]="officeScheduleUrl$ | async"
           data-cy="navbar-appointments-office-item">
          Office Schedule
        </a>
        <a ngbDropdownItem
           [href]="urls.virtualVisits"
           data-cy="navbar-appointments-virtual-item">
          Virtual Visits
        </a>
      </div>
    </div>
    <a class="menu-item text-item"
       [href]="urls.docs"
       data-cy="navbar-docs-button">
      Docs
    </a>
    <a class="menu-item text-item"
       [href]="urls.faxes"
       data-cy="navbar-faxes-button">
      Faxes
    </a>
    <a class="menu-item text-item"
       [href]="urls.contacts"
       data-cy="navbar-contacts-button">
      Contacts
    </a>
    <a class="menu-item text-item"
       [href]="urls.providers"
       data-cy="navbar-providers-button">
      Providers
    </a>
    <a class="menu-item text-item active"
       routerLink="/"
       data-cy="navbar-template-button">
      Template Manager
    </a>
  </div>

  <div ngbDropdown>
    <a ngbDropdownToggle
       class="menu-item text-item">
      {{ userName$ | async }}
    </a>
    <div ngbDropdownMenu>
      <a ngbDropdownItem
         [href]="urls.myAccount">
        My Account
      </a>
      <button ngbDropdownItem
              (click)="logout()">
        Logout
      </button>
    </div>
  </div>

</nav>
