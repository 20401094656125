<div class="modal-header template-modal-header d-flex justify-content-between">
  <span class="h3" *ngIf="context.type === 'message'">
    {{ mode | titlecase }} Message Template
  </span>
  <span class="h3" *ngIf="context.type === 'text'">
    {{ mode | titlecase }} Snippet Template
  </span>

  <button
    type="button"
    class="om-icon icon-close"
    (click)="modal.close('Close click')"
  ></button>
</div>
<form [formGroup]="form" (ngSubmit)="doSave()">
  <div class="modal-body">
    <div class="modal-field-header">
      Title
      <span class="star"> * </span>
    </div>
    <div class="modal-field">
      <input
        *ngIf="mode === 'create' || mode === 'edit'; else readOnlyTitle"
        type="text"
        formControlName="name"
        placeholder="Write a title that will be used in search"
        data-cy="title-input"
        class="w-100"
      />
      <ng-template #readOnlyTitle>
        <div class="template-content-readonly" data-cy="template-title">
          {{ template.name }}
        </div>
      </ng-template>
      <div
        *ngIf="nameControl.invalid && nameControl.touched"
        class="field-error"
      >
        Please provide a template title.
      </div>
    </div>
    <div class="d-flex">
      <div class="flex-1">
        <div class="modal-field-header">Tags</div>
        <div
          *ngIf="mode === 'create' || mode === 'edit'; else readOnlyTags"
          class="modal-field pr-3 category-select"
        >
          <omg-tags-editor
            [control]="tagsControl"
            [template]="template"
            [tags]="tags"
          >
          </omg-tags-editor>
        </div>
        <ng-template #readOnlyTags>
          <label class="read-only-tag" *ngFor="let tag of template.tags">
            {{ tag.name }}
          </label>
        </ng-template>
      </div>
      <div class="flex-1">
        <div class="modal-field-header">Purpose</div>
        <div
          class="modal-field d-flex purpose-radio-options"
          *ngIf="mode === 'create' || mode === 'edit'; else readOnlyPurpose"
        >
          <div>
            <input
              type="radio"
              value="public"
              id="public"
              formControlName="purpose"
              data-cy="purpose-radio-button"
            />
            <label for="public" class="ml-2"> Public </label>
          </div>
          <div class="ml-5">
            <input
              type="radio"
              value="personal"
              id="personal"
              formControlName="purpose"
              data-cy="purpose-radio-button"
            />
            <label for="personal" class="ml-2"> Personal </label>
          </div>
          <div class="ml-5" *ngIf="autoLabsRadioOptionVisible">
            <input
              type="radio"
              value="auto_labs"
              id="auto_labs"
              formControlName="purpose"
              data-cy="purpose-radio-button"
            />
            <label for="auto_labs" class="ml-2"> Auto-Labs </label>
          </div>
        </div>
        <ng-template #readOnlyPurpose>
          <p class="purpose" data-cy="template-purpose">
            {{ templateService.purposeDisplayValue(template) }}
          </p>
        </ng-template>
      </div>
    </div>
    <div class="modal-field-header">
      Body
      <span class="star"> * </span>
    </div>
    <div class="modal-field">
      <quill-editor
        *ngIf="mode === 'create' || mode === 'edit'; else readOnlyBody"
        class="quill"
        [format]="quillFormat"
        [modules]="quillModules"
        placeholder="Add your template content here…"
        data-cy="body-input"
        formControlName="body"
      >
      </quill-editor>
      <ng-template #readOnlyBody>
        <div
          class="template-content-readonly"
          data-cy="template-body"
          [innerHTML]="template.body"
        ></div>
      </ng-template>
      <div
        *ngIf="bodyControl.invalid && bodyControl.touched"
        class="field-error"
      >
        Please enter the template body content.
      </div>
    </div>
  </div>

  <omg-variable-accordion [user]="user"></omg-variable-accordion>

  <div class="modal-footer action-bar d-flex justify-content-start">
    <button
      *ngIf="mode === 'edit' || mode === 'create'"
      type="submit"
      class="om-button -primary"
      data-cy="save-button"
    >
      Save
    </button>
    <button
      type="button"
      class="om-button -flat"
      (click)="modal.close('Close click')"
      data-cy="close-button"
    >
      Close
    </button>
  </div>
</form>
