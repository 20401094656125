import { ProfileSelectors, Profile } from '@app/core/profile';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { TemplatePurpose } from '@app/shared/models/template-purpose';
import { Template } from '@app/shared/models/template';
import { Role } from '../models/role';

@Injectable()
export class TemplateService {

  constructor(
    private profileSelectors: ProfileSelectors,
  ) { }

  isEditable(template: Template): Observable<boolean> {
    return this
      .profileSelectors
      .profile
      .pipe(
        map(profile => this.editable(template, profile))
      );
  }

  isDeletable(template: Template): Observable<boolean> {
    return this
      .profileSelectors
      .profile
      .pipe(
        map(profile => this.deletable(template, profile))
      );
  }

  purpose(template: Template): TemplatePurpose {
    if (template.purpose) {
      return template.purpose;
    }
    return template.internalUserId ? 'personal' : 'public';
  }

  purposeDisplayValue(template: Template): string {
    const purpose = this.purpose(template);
    return (purpose === 'auto_labs') ? 'Auto-Labs' : purpose;
  }

  /**
   * users with 'automated_template_editor' can edit all auto_labs templates.
   * users with role 'template_editor' role can edit all public templates.
   * users without a special role can edit their own templates.
   */
  editable(template: Template, profile: Profile): boolean {
    const roles = profile.roles || [];
    if (template.purpose === 'auto_labs') {
      return roles.includes(Role.AutomatedTemplateEditor);
    }
    if (template.purpose === 'public' || !template.internalUserId) {
      return roles.includes(Role.TemplateEditor);
    }
    if (template.purpose === 'personal' || template.internalUserId) {
      return true;
    }
    return false;
  }

  deletable(template: Template, profile: Profile): boolean {
    const roles = profile.roles || [];
    if (template.purpose === 'auto_labs') {
      return roles.includes(Role.AutomatedTemplateEditor);
    }
    if (template.purpose === 'public' || !template.internalUserId) {
      return roles.includes(Role.TemplateEditor);
    }
    if (template.purpose === 'personal' || template.internalUserId) {
      return true;
    }
    return false;
  }
}
