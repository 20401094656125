<label>
  {{label}}
</label>

<button class="om-button -flat"
        (click)="onPrev()"
        [disabled]="prevDisabled"
        data-cy="prev-page-button">
  <fa-icon [icon]="chevronLeftIcon"></fa-icon>
</button>

<button class="om-button -flat"
        (click)="onNext()"
        [disabled]="nextDisabled"
        data-cy="next-page-button">
  <fa-icon [icon]="chevronRightIcon"></fa-icon>
</button>
