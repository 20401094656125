import { Component, Output, EventEmitter, Input } from '@angular/core';
import { Template } from '@app/shared/models/template';
import { Tag } from '@app/features/tag/shared/tag.type';

@Component({
  selector: 'omg-tags-list',
  templateUrl: './tags-list.component.html',
  styleUrls: ['./tags-list.component.scss']
})
export class TagsListComponent {

  @Input() template: Template

  @Output() tagClick: EventEmitter<string> = new EventEmitter();

  truncateAfter = 3;

  get templateTags(): Tag[] {
    return this.template.tags as Tag[];
  }
}
