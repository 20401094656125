<ng-select class="tags-select-editor"
           placeholder="Assign tags such as 'Admin' or 'Billing'"
           [items]="tags"
           [searchable]="true"
           [multiple]="true"
           [clearable]="false"
           (search)="onSearch($event)"
           (blur)="closeDropdown()"
           (add)="closeDropdown()"
           [addTag]="createTag"
           [isOpen]="tagDropdownIsOpen"
           bindLabel="name"
           bindValue="id"
           [formControl]="control"
           data-cy="tags-select-editor"
           addTagText="Create New: ">

    <ng-template ng-multi-label-tmp
                 let-items="items"
                 let-clear="clear">
        <label class="ng-value"
               *ngFor="let item of items">
            <span class="ng-value-label">
                {{item.name}}
            </span>
            <span class="ng-value-icon"
                  (click)="clear(item)"
                  aria-hidden="true">
                ×
            </span>
        </label>
    </ng-template>
</ng-select>
