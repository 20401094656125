import { InjectionToken } from '@angular/core';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { ActionReducerMap } from '@ngrx/store';
import {
  ActionNameTypeMapFactory,
  buildTokenName,
  StateAction,
  StateConfig,
  stateReducerFactory,
  StoreEntityState,
  ActionNames,
} from '@app/core/store/shared/state';
import {
  TextTemplateActionTypes,
  SearchSuccess,
  PaginateSuccess,
  SearchError,
  Search,
  Paginate,
  TextTemplateCustomAction,
} from './text-templates.actions';
import { SearchFilters } from '@app/shared/models/search-filters';
import { PaginationMetadata } from '@app/shared/models/pagination-metadata';
import { Template } from '@app/shared/models/template';

export const textTemplatesStateConfig: StateConfig = {
  statePath: 'textTemplates',
  entityName: 'TextTemplates',
  pluralName: 'TextTemplatess',
};

export interface TextTemplatesState extends StoreEntityState<Template> {
  filters: SearchFilters;
  pagination: PaginationMetadata;
  templates: Template[];
}

export const textTemplatesActionNameTypeMap = ActionNameTypeMapFactory(
  textTemplatesStateConfig
);

export const CreateSuccessAction = textTemplatesActionNameTypeMap[ActionNames.CreateSuccess];
export const UpdateSuccessAction = textTemplatesActionNameTypeMap[ActionNames.UpdateSuccess];
export const DeleteSuccessAction = textTemplatesActionNameTypeMap[ActionNames.DeleteSuccess];

export function selectTextTemplatesId(textTemplate: Template): number {
  return textTemplate && textTemplate.id;
}

export const textTemplatesEntityAdapter: EntityAdapter<Template> =
  createEntityAdapter<Template>({
    selectId: selectTextTemplatesId,
  });

export const textTemplatesInitialState: Partial<TextTemplatesState> = {
  filters: {
    searchTerm: '',
    purpose: 'all',
    selectedTags: [],
  },
  pagination: {
    offset: 0,
    limit: 50,
    total: 0,
  },
  templates: [],
  loading: true,
};

export const TextTemplatesReducerToken =
  new InjectionToken<ActionReducerMap<StoreEntityState<Template>>>(buildTokenName(textTemplatesStateConfig));

export type TextTemplateAction = StateAction<Template> | TextTemplateCustomAction;

export function textTemplatesReducer(
  state = textTemplatesInitialState as TextTemplatesState,
  action: TextTemplateAction,
): TextTemplatesState {
  switch (action.type) {
  case TextTemplateActionTypes.SEARCH:
  case TextTemplateActionTypes.PAGINATE: {
    const payload = (action as Search | Paginate).payload;
    return {
      ...state,
      loading: true,
      filters: {
        ...payload.filters,
      },
      error: null,
    };
  }
  case TextTemplateActionTypes.SEARCH_SUCCESS:
  case TextTemplateActionTypes.PAGINATE_SUCCESS: {
    const payload = (action as SearchSuccess | PaginateSuccess).payload;
    return {
      ...state,
      pagination: {
        offset: payload.offset,
        limit: payload.limit,
        total: payload.total,
      },
      templates: payload.items,
      loading: false,
      error: null,
    };
  }
  case TextTemplateActionTypes.SEARCH_ERROR: {
    const payload = (action as SearchError).payload;
    return {
      ...state,
      error: payload,
      loading: false,
    };
  }
  default: {
    return { ...state };
  }
  }
}

export const textTemplatesReducerFactory = () => {
  return stateReducerFactory<Template>(
    textTemplatesActionNameTypeMap,
    textTemplatesEntityAdapter,
    textTemplatesReducer,
    textTemplatesInitialState,
  );
};
