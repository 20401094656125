import { camelCase as _camelCase, snakeCase as _snakeCase } from 'lodash/fp';

import {
  curry,
  isArray,
  isDate,
  isRegExp,
  isString,
  map,
  reduce,
} from '../shared';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type KeyMapFunc = (arg: any) => string;

const mapKeysDeep = curry((keyMapFunc: KeyMapFunc, obj) => {
  if (!obj || typeof obj !== 'object') {
    return obj;
  }
  if (isDate(obj) || isRegExp(obj)) {
    return obj;
  }
  if (isArray(obj)) {
    return map(mapKeysDeep(keyMapFunc), obj);
  }
  return reduce(
    (acc, key) => {
      const newKey = keyMapFunc(key);
      acc[newKey] = mapKeysDeep(keyMapFunc, obj[key]);
      return acc;
    },
    {},
    Object.keys(obj),
  );
});

const mapObjKeys = curry((mapKeyFunc: KeyMapFunc, obj) =>
  isString(obj) ? mapKeyFunc(obj) : mapKeysDeep(mapKeyFunc, obj),
);

export const camelCase = obj => mapObjKeys(_camelCase, obj);

export const snakeCase = obj => mapObjKeys(_snakeCase, obj);
