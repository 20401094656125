import { ProfileOfficeResponse } from '@app/core/profile/shared/profile-response.type';

export const fullAddressDisplay = (
  address: ProfileOfficeResponse,
  separator = ',',
): string => {
  if (!address) {
    return '';
  }

  let display = (address as ProfileOfficeResponse).address1;
  const zip = (address as ProfileOfficeResponse).zip;
  const state =
    address.state && (address as ProfileOfficeResponse).state.short_name;
  if (address.address2) {
    display += `${separator} ${address.address2}`;
  }
  return `${display}${separator} ${address.city}, ${state} ${zip}`;
};
