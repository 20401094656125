import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { TagStoreModule } from './store/tag-store.module';

@NgModule({
  imports: [
    SharedModule,
    TagStoreModule,
  ],
})
export class TagModule { }
