import { Actions, ofType } from '@ngrx/effects';
import { mapTo } from 'rxjs/operators';

import { ActionNames } from '@app/core/store/shared/state';
import { messageTemplatesActionNameTypeMap } from '@app/features/message-templates/store/message-templates.reducer';
import { textTemplatesActionNameTypeMap } from '@app/features/text-templates/store/text-templates.reducer';

export const templateSuccessToasts$ = (actions$: Actions) =>
  actions$.pipe(
    ofType(
      messageTemplatesActionNameTypeMap[ActionNames.CreateSuccess],
      messageTemplatesActionNameTypeMap[ActionNames.UpdateSuccess],
      textTemplatesActionNameTypeMap[ActionNames.CreateSuccess],
      textTemplatesActionNameTypeMap[ActionNames.UpdateSuccess]
    ),
    mapTo({
      severity: 'success',
      summary: 'Success',
      detail: 'The template has been saved.',
    })
  );

export const templateErrorToasts$ = (actions$: Actions) =>
  actions$.pipe(
    ofType(
      messageTemplatesActionNameTypeMap[ActionNames.CreateError],
      messageTemplatesActionNameTypeMap[ActionNames.UpdateError],
      textTemplatesActionNameTypeMap[ActionNames.CreateError],
      textTemplatesActionNameTypeMap[ActionNames.UpdateError]
    ),
    mapTo({
      severity: 'error',
      summary: 'Error',
      detail: 'Unfortunately, we were unable to save template changes.',
    })
  );
