import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@app/shared/shared.module';
import { TextTemplatesComponent } from './components/text-templates/text-templates.component';
import { TextTemplatesStoreModule } from './store/text-templates-store.module';

@NgModule({
  imports: [
    SharedModule,
    TextTemplatesStoreModule,
    NgbModule,
  ],
  exports: [
    TextTemplatesComponent,
  ],
  declarations: [
    TextTemplatesComponent,
  ]
})
export class TextTemplatesModule { }
