import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ApiService } from '@app/core';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';

import { MessageTemplatesApiService } from '../shared/message-templates-api.service';
import { MessageTemplatesActions } from './message-templates.actions';
import { MessageTemplatesEffects } from './message-templates.effects';
import {
  messageTemplatesActionNameTypeMap,
  MessageTemplatesReducerToken,
  messageTemplatesStateConfig,
  messageTemplatesReducerFactory
} from './message-templates.reducer';
import { MessageTemplatesSelectors } from './message-templates.selectors';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(messageTemplatesStateConfig.statePath, MessageTemplatesReducerToken),
    EffectsModule.forFeature([MessageTemplatesEffects]),
  ],
  providers: [
    {
      provide: MessageTemplatesReducerToken,
      useFactory: messageTemplatesReducerFactory,
    },
    {
      provide: MessageTemplatesSelectors,
      useFactory: store => new MessageTemplatesSelectors(store),
      deps: [Store],
    },
    {
      provide: MessageTemplatesActions,
      useFactory: store => new MessageTemplatesActions(store, messageTemplatesActionNameTypeMap),
      deps: [Store],
    },
    {
      provide: MessageTemplatesApiService,
      useFactory: (apiService) =>
        new MessageTemplatesApiService(apiService),
      deps: [ApiService],
    },
  ],
})
export class MessageTemplatesStoreModule { }
