import { InjectionToken } from '@angular/core';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { ActionReducerMap } from '@ngrx/store';
import {
  ActionNameTypeMapFactory,
  buildTokenName,
  StateAction,
  StateConfig,
  stateReducerFactory,
  StoreEntityState,
  ActionNames,
} from '@app/core/store/shared/state';
import {
  MessageTemplateActionTypes,
  SearchSuccess,
  PaginateSuccess,
  SearchError,
  Search,
  Paginate,
  MessageTemplateCustomAction,
} from './message-templates.actions';
import { SearchFilters } from '@app/shared/models/search-filters';
import { PaginationMetadata } from '@app/shared/models/pagination-metadata';
import { Template } from '@app/shared/models/template';

export const messageTemplatesStateConfig: StateConfig = {
  statePath: 'messageTemplates',
  entityName: 'MessageTemplates',
  pluralName: 'MessageTemplatess',
};

export interface MessageTemplatesState extends StoreEntityState<Template> {
  filters: SearchFilters;
  pagination: PaginationMetadata;
  templates: Template[];
}

export const messageTemplatesActionNameTypeMap = ActionNameTypeMapFactory(
  messageTemplatesStateConfig
);

export const CreateSuccessActionType = messageTemplatesActionNameTypeMap[ActionNames.CreateSuccess];
export const UpdateSuccessActionType = messageTemplatesActionNameTypeMap[ActionNames.UpdateSuccess];
export const DeleteSuccessActionType = messageTemplatesActionNameTypeMap[ActionNames.DeleteSuccess];

export function selectMessageTemplatesId(template: Template): number {
  return template && template.id;
}

export const messageTemplatesEntityAdapter: EntityAdapter<Template> =
  createEntityAdapter<Template>({
    selectId: selectMessageTemplatesId,
  });

export const messageTemplatesInitialState: Partial<MessageTemplatesState> = {
  filters: {
    searchTerm: '',
    purpose: 'all',
    selectedTags: [],
  },
  pagination: {
    offset: 0,
    limit: 50,
    total: 0,
  },
  templates: [],
  loading: true,
};

export const MessageTemplatesReducerToken =
  new InjectionToken<ActionReducerMap<StoreEntityState<Template>>>(buildTokenName(messageTemplatesStateConfig));

export type MessageTemplateAction = StateAction<Template> | MessageTemplateCustomAction;

export function messageTemplatesReducer(
  state = messageTemplatesInitialState as MessageTemplatesState,
  action: MessageTemplateAction
): MessageTemplatesState {

  switch (action.type) {
  case MessageTemplateActionTypes.SEARCH:
  case MessageTemplateActionTypes.PAGINATE: {
    const payload = (action as Search | Paginate).payload;
    return {
      ...state,
      loading: true,
      filters: {
        ...payload.filters,
      },
      error: null,
    };
  }

  case MessageTemplateActionTypes.SEARCH_SUCCESS:
  case MessageTemplateActionTypes.PAGINATE_SUCCESS: {
    const payload = (action as SearchSuccess | PaginateSuccess).payload;
    return {
      ...state,
      pagination: {
        offset: payload.offset,
        total: payload.total,
        limit: payload.limit,
      },
      templates: payload.items,
      loading: false,
      error: null,
    };
  }

  case MessageTemplateActionTypes.SEARCH_ERROR: {
    const payload = (action as SearchError).payload;
    return {
      ...state,
      error: payload,
      loading: false,
    };
  }

  default: {
    return { ...state };
  }
  }
}

export const messageTemplatesReducerFactory = () => {
  return stateReducerFactory<Template>(
    messageTemplatesActionNameTypeMap,
    messageTemplatesEntityAdapter,
    messageTemplatesReducer,
    messageTemplatesInitialState,
  );
};
