/* eslint-disable no-console */
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type LogArgument = any;

@Injectable()
export class LoggerService {
  info(...args: LogArgument[]) {
    const [first, ...rest] = args;
    // eslint-disable-next-line no-restricted-syntax
    console.info(first, rest);
  }

  log(...args: LogArgument[]) {
    const [first, ...rest] = args;
    console.log(first, rest);
  }

  warning(...args: LogArgument[]) {
    const [first, ...rest] = args;
    console.warn(first, rest);
  }

  error(...args: LogArgument[]) {
    const [first, ...rest] = args;
    console.error(first, rest);

    if (first !== null && typeof first === 'object') {
      if (first instanceof Error) {
        Sentry.captureException(first);
      } else if ('error' in first && first.error instanceof Error) {
        Sentry.captureException(first.error);
      } else if ('message' in first && first.message) {
        Sentry.captureException(first.message);
      }
    }
  }
}
