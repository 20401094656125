import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ErrorHandlerService } from '@app/core';
import { StateEffects } from '@app/core/store/shared/state';
import { TextTemplatesApiService } from '../shared/text-templates-api.service';
import { textTemplatesActionNameTypeMap } from './text-templates.reducer';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { switchMap, map, catchError } from 'rxjs/operators';
import {
  TextTemplateActionTypes,
  Paginate,
  PaginateSuccess,
  Search,
  SearchSuccess,
  SearchError,
} from './text-templates.actions';
import { TemplateSearchService } from '@app/shared/services/template-search.service';
import { Template } from '@app/shared/models/template';

@Injectable()
export class TextTemplatesEffects extends StateEffects<Template> {

  constructor(
    actions$: Actions,
    protected templatesApi: TextTemplatesApiService,
    protected searchService: TemplateSearchService,
    protected errorHandler: ErrorHandlerService,
  ) {
    super(
      actions$,
      templatesApi,
      textTemplatesActionNameTypeMap,
      errorHandler
    );
  }

  @Effect()
  createTextTemplates$ = this.createEntity$;

  @Effect()
  loadTextTemplatess$ = this.loadEntities$;

  @Effect()
  getTextTemplatesById$ = this.getEntityById$;

  @Effect()
  updateTextTemplates$ = this.updateEntity$;

  @Effect()
  deleteTextTemplates$ = this.deleteEntity$;

  @Effect()
  search$: Observable<Action> = this
    .actions$
    .pipe(
      ofType<Search>(TextTemplateActionTypes.SEARCH),
      map(action => action.payload),
      switchMap(criteria => this.searchService.search(criteria)),
      map(result => new SearchSuccess(result)),
      catchError(err => of(new SearchError(this.errorHandler.handleErrorSafe(err))))
    );

  @Effect()
  paginate$: Observable<Action> = this
    .actions$
    .pipe(
      ofType<Paginate>(TextTemplateActionTypes.PAGINATE),
      map(action => action.payload),
      switchMap(criteria => this.searchService.search(criteria)),
      map(result => new PaginateSuccess(result))
    );
}
