import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { ErrorHandlerService } from '../../errors/error-handler.service';
import { ProfileApiService } from '../shared/profile-api.service';

import {
  GET_PROFILE,
  GetProfile,
  GetProfileError,
  GetProfileSuccess,
} from './profile.actions';
import { ProfileState } from './profile.reducer';
import { selectProfileId } from './profile.selectors';

@Injectable()
export class ProfileEffects {
  constructor(
    private action$: Actions,
    private store: Store<ProfileState>,
    private profileApiService: ProfileApiService,
    private errorHandler: ErrorHandlerService
  ) {}

  @Effect()
  getProfile$: Observable<Action> = this.action$.pipe(
    ofType(GET_PROFILE),
    withLatestFrom(this.store.pipe(select(selectProfileId))),
    filter(([_, profileId]: [GetProfile, number]) => !profileId),
    switchMap(() =>
      this.profileApiService.get().pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((data: any) => new GetProfileSuccess(data)),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        catchError((error: any) =>
          of(new GetProfileError(this.errorHandler.handleErrorSafe(error)))
        )
      )
    )
  );
}
