import { InjectionToken } from '@angular/core';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { ActionReducerMap } from '@ngrx/store';

import {
  ActionNameTypeMapFactory,
  buildTokenName,
  StateAction,
  StateConfig,
  stateReducerFactory,
  StoreEntityState,
  ActionNames,
} from '@app/core/store/shared/state';

import { Tag } from '../shared/tag.type';

export const tagStateConfig: StateConfig = {
  statePath: 'tag',
  entityName: 'Tag',
  pluralName: 'Tags',
};

export const tagActionNameTypeMap = ActionNameTypeMapFactory(
  tagStateConfig,
);

export function selectTagId(tag: Tag): number {
  return tag && tag.id;
}

export const tagEntityAdapter: EntityAdapter<
  Tag
> = createEntityAdapter<Tag>({
  selectId: selectTagId,
});

export const initialState: StoreEntityState<
  Tag
> = tagEntityAdapter.getInitialState({
  loading: false,
  error: null,
});

export const CreateSuccessAction = tagActionNameTypeMap[ActionNames.CreateSuccess];

export const TagReducerToken = new InjectionToken<
  ActionReducerMap<StoreEntityState<Tag>>
>(buildTokenName(tagStateConfig));

export function tagReducer(
  state = initialState,
  action: StateAction<Tag>
): StoreEntityState<Tag> {
  switch (action.type) {
  default: {
    return { ...state };
  }
  }
}

export const tagReducerFactory = () => {
  return stateReducerFactory<Tag>(
    tagActionNameTypeMap,
    tagEntityAdapter,
    tagReducer,
  );
};
