import { ActionReducerMap } from '@ngrx/store';

import {
  routerReducer,
  RouterState,
  routerStateKey,
} from '../router/router.reducer';

export interface AppState {
  [routerStateKey]: RouterState;
}

export const initialAppState: AppState = {
  router: null,
};

export const appReducer: ActionReducerMap<AppState> = {
  router: routerReducer,
};
