import { Resolve } from '@angular/router';
import { Observable, noop } from 'rxjs';
import { tap, first } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Tag } from '@app/features/tag/shared/tag.type';
import { TagSelectors } from '@app/features/tag/store/tag.selectors';
import { TagActions } from '@app/features/tag/store/tag.actions';

@Injectable()
export class TemplateTagsResolver implements Resolve<Tag[]> {

  constructor(
    private selectors: TagSelectors,
    private actions: TagActions,
  ) {}

  resolve(): Observable<Tag[]> {
    // fetch tags from the store.
    // if the tags don't exist in the store, fire action to retrieve them from the api.
    // resolve once the tags actually exist in the store.
    return this
      .selectors
      .entities
      .pipe(
        tap(entities => entities.length === 0 ? this.actions.load() : noop()),
        first(entities => entities.length > 0),
      );
  }
}
