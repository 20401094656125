import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';

import { ToastMessageComponent } from './toast-message.component';

@NgModule({
  imports: [CommonModule, NgbToastModule, FontAwesomeModule, RouterModule],
  declarations: [ToastMessageComponent],
  exports: [NgbToastModule, ToastMessageComponent],
})
export class ToastMessageModule {}
