import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  createFeatureSelector,
  createSelector,
  select,
  Store,
} from '@ngrx/store';
import { Observable } from 'rxjs';
import { TextTemplatesState } from './text-templates.reducer';
import { SearchFilters } from '@app/shared/models/search-filters';
import { PaginationMetadata } from '@app/shared/models/pagination-metadata';
import { Template } from '@app/shared/models/template';

export class TextTemplatesSelectors {

  protected adapter: EntityAdapter<Template> = createEntityAdapter<Template>();

  protected selectState = createFeatureSelector<TextTemplatesState>('textTemplates');

  protected selectTemplates = createSelector(this.selectState, state => state.templates);

  protected selectFilters = createSelector(this.selectState, (state) => {
    const filters: SearchFilters = {
      ...state.filters,
    };
    return filters;
  });

  protected selectPagination = createSelector(this.selectState, (state) => {
    const pagination: PaginationMetadata = {
      ...state.pagination,
    };
    return pagination;
  });

  protected selectError = createSelector(this.selectState, (state) => {
    return state.error;
  });

  protected selectEntitiesDictionary = createSelector(this.selectState, this.adapter.getSelectors().selectEntities);

  protected selectEntity = createSelector(this.selectEntitiesDictionary, (entities, { id }) => entities[id]);

  protected selectLoading = createSelector(this.selectState, (state) => state.loading);

  constructor(private store: Store<Template>) { }

  get filters(): Observable<SearchFilters> {
    return this.store.pipe(select(this.selectFilters));
  }

  getById(id: number): Observable<Template> {
    return this.store.pipe(select(this.selectEntity, { id }));
  }

  get templates(): Observable<Template[]> {
    return this.store.pipe(select(this.selectTemplates));
  }

  get pagination(): Observable<PaginationMetadata> {
    return this.store.pipe(select(this.selectPagination));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get error(): Observable<any> {
    return this.store.pipe(select(this.selectError));
  }

  get loading(): Observable<boolean> {
    return this.store.pipe(select(this.selectLoading));
  }
}
