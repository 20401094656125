import { Component, Output, OnInit, EventEmitter } from '@angular/core';
import { faChevronLeft, faChevronRight, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { PageEvent } from '@app/shared/models/page-event';

@Component({
  selector: 'omg-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {

  @Output() prev: EventEmitter<PageEvent> = new EventEmitter();
  @Output() next: EventEmitter<PageEvent> = new EventEmitter();

  readonly chevronLeftIcon: IconDefinition = faChevronLeft;
  readonly chevronRightIcon: IconDefinition = faChevronRight;

  label: string;
  prevDisabled: boolean;
  nextDisabled: boolean;

  private offset = 0;
  private limit = 0;
  private total = 0;

  ngOnInit(): void {
    this.update();
  }

  onPrev(): void {
    if (this.offset > 0) {
      this.offset = Math.max(0, this.offset - this.limit);
    }
    this.update();
    this.prev.emit({
      offset: this.offset,
      limit: this.limit,
    });
  }

  onNext(): void {
    if (this.offset < this.total) {
      this.offset = Math.min(this.offset + this.limit, this.total);
    }
    this.update();
    this.next.emit({
      offset: this.offset,
      limit: this.limit,
    });
  }

  init(limit: number, total: number): void {
    this.offset = 0;
    this.limit = limit;
    this.total = total;
    this.update();
  }

  private update(): void {
    if (this.total > 0) {
      const start = this.offset + 1;
      const end = Math.min(this.offset + this.limit, this.total);
      this.label = `${start}-${end} of ${this.total}`;
    } else {
      this.label = '0 of 0';
    }
    this.prevDisabled = (this.offset === 0);
    this.nextDisabled = (this.offset + this.limit >= this.total);
  }
}
