import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { ConfigService } from '@app/core/config';
import { apiProviders } from './api/api-providers';
import { LoggerService } from './logger/logger.service';
import { LoginModule } from './login/login.module';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { SearchModule } from './search/search.module';
import { AppStoreModule } from './store/app-store.module';
import { UnauthorizedModule } from './unauthorized/unauthorized.module';
import { ProfileModule } from './profile/profile.module';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    AppStoreModule,
    SearchModule,
    LoginModule,
    UnauthorizedModule,
    ProfileModule,
  ],
  providers: [
    LoggerService,
    ConfigService,
    ...apiProviders,
  ],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
      parentModule: CoreModule,
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
