import { Observable, Subscription } from 'rxjs';
import { filter, startWith, debounceTime, withLatestFrom, map } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { TextTemplatesActions } from '../../store/text-templates.actions';
import { TextTemplatesSelectors } from '../../store/text-templates.selectors';
import { SearchFilters } from '@app/shared/models/search-filters';
import { ProfileSelectors } from '@app/core/profile';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { CreateSuccessAction, UpdateSuccessAction, DeleteSuccessAction } from '../../store/text-templates.reducer';
import { SearchCriteria } from '@app/shared/models/search-criteria';
import { Template } from '@app/shared/models/template';
import { TemplateSearchFiltersComponent } from '@app/shared/components/template-search-filters/template-search-filters.component';
import { TextTemplateContextProvider } from '@app/shared/providers/template-context.provider';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';

/**
 * The TemplateContext provider is specified here so the text-template-specific
 * instance is limited to this component and its children.
 * https://angular.io/guide/providers#limiting-provider-scope-with-components
 */
@Component({
  selector: 'omg-text-templates',
  templateUrl: './text-templates.component.html',
  styleUrls: ['./text-templates.component.scss'],
  providers: [
    TextTemplateContextProvider,
  ],
})
export class TextTemplatesComponent implements OnInit, OnDestroy {

  @ViewChild(TemplateSearchFiltersComponent, { static: true })
  filterComponent: TemplateSearchFiltersComponent;

  templates$: Observable<Template[]>;
  loading$: Observable<boolean>;
  searchForm: UntypedFormGroup;

  /**
   * stores all subscriptions created during the lifespan of this component
   * so they can all be easily unsubscribed on destroy
   */
  private subscriptions: Subscription = new Subscription();

  private searchFilters: SearchFilters = {
    searchTerm: '',
    purpose: 'all',
    selectedTags: [],
  };

  constructor(
    private launchDarklyService: LaunchDarklyService,
    private fb: UntypedFormBuilder,
    private textTemplatesSelectors: TextTemplatesSelectors,
    private textTemplatesActions: TextTemplatesActions,
    private profileSelectors: ProfileSelectors,
    private actions$: Actions,
  ) { }

  ngOnInit(): void {
    this.templates$ = this.textTemplatesSelectors.templates;
    this.loading$ = this.textTemplatesSelectors.loading;
    this.searchForm = this.fb.group(this.searchFilters);
    this.initSearchFilterSubscription();
    this.initTemplateChangeSubscription();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private initSearchFilterSubscription(): void {
    // subscribe to filter changes and dispatch search action whenever the search fields are modified
    if (this.useOpenSearchProxy()) {
      const subscription = this
        .searchForm
        .valueChanges
        .pipe(
          startWith(this.searchFilters),
          filter(filters => filters.searchTerm.length > 1 || filters.searchTerm === ''),
          debounceTime(500),
          withLatestFrom(this.textTemplatesSelectors.pagination, this.profileSelectors.profileId),
          map(([filters, pagination, userId]) => {
            const criteria: SearchCriteria = {
              filters,
              offset: 0,
              limit: pagination.limit,
              userId,
              type: 'text',
              index: 'text_templates',
            };
            return criteria;
          })
        )
        .subscribe(criteria => this.textTemplatesActions.search(criteria));
      this.subscriptions.add(subscription);
    } else {
      console.error('Feature flag is set to use GraphQL for text_templates, but that is not supported yet!');
    }
  }

  private initTemplateChangeSubscription(): void {
    // subscriptions that listens for template create/update success events.
    // this ensures the table is refreshed when one of these events occurs.
    // we trigger a 'new' search with the current offset/limit so that the pagination counts get updated.
    if (this.useOpenSearchProxy()) {
      const subscription = this
        .actions$
        .pipe(
          ofType(CreateSuccessAction, UpdateSuccessAction, DeleteSuccessAction),
          withLatestFrom(this.textTemplatesSelectors.filters, this.textTemplatesSelectors.pagination, this.profileSelectors.profileId),
          map(([_, filters, pagination, userId]) => {
            const criteria: SearchCriteria = {
              filters,
              offset: pagination.offset,
              limit: pagination.limit,
              userId,
              type: 'text',
              index: 'text_templates',
            };
            return criteria;
          })
        )
        .subscribe(criteria => this.textTemplatesActions.search(criteria));
      this.subscriptions.add(subscription);
    } else {
      console.error('Feature flag is set to use GraphQL for text_templates, but that is not supported yet!');
    }
  }

  private useOpenSearchProxy(): boolean {
    const osProxyFlag = this.launchDarklyService.variation('os-proxy-text-templates', false);
    // Off (default) = use OS Proxy, On (needs approval) = use GQL
    return !osProxyFlag;
  }
}
