<ng-select class="tags-select-field"
           [items]="tags$ | async"
           [searchable]="true"
           [multiple]="true"
           (search)="onSearch($event)"
           (blur)="closeDropdown()"
           (add)="onAdd()"
           (remove)="updateWidth()"
           (clear)="updateWidth()"
           [isOpen]="open"
           bindLabel="name"
           bindValue="name"
           placeholder="Workflow Tags"
           [formControl]="control">

  <ng-template ng-multi-label-tmp
               let-items="items"
               let-clear="clear">
    <label class="ng-value"
           *ngFor="let item of items | slice:0:truncateAfter">
      <span class="ng-value-label">
        {{item.name}}
      </span>
      <span class="ng-value-icon"
            (click)="clear(item)"
            aria-hidden="true">
        ×
      </span>
    </label>
    <label class="ng-value"
           *ngIf="items.length > truncateAfter">
      <span class="ng-value-label">
        +{{items.length - truncateAfter}}
      </span>
    </label>
  </ng-template>

</ng-select>
