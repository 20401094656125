import * as LDsdk from 'launchdarkly-js-client-sdk';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { ConfigService, Profile, ProfileSelectors } from '@app/core';

@Injectable()
export class LaunchDarklyService {

  private ldclient: LDsdk.LDClient;
  readonly initialized$ = new ReplaySubject<boolean>(1);

  constructor(
    private configService: ConfigService,
    private profileSelectors: ProfileSelectors,
  ) { }

  /**
   * Returns an observable that emits true when the sdk is initialized.
   */
  init(): Observable<boolean> {
    // initializing the launchdarkly sdk is an asynchronous operation.
    // it makes an api call to launchdarkly cloud and once its ready, it emits
    // the 'ready' event indicating methods can be invoked on it.
    // https://docs.launchdarkly.com/sdk/client-side/javascript#code-sample
    const config = this.configService.environment.launchdarkly;
    if (config && config.clientSideId) {
      this
        .profileSelectors
        .profile
        .pipe(
          first(profile => (profile ? true : false))
        )
        .subscribe(profile => {
          const user = this.buildUser(profile);
          this.ldclient = LDsdk.initialize(config.clientSideId, user);
          this.ldclient.on('ready', () => {
            this.initialized$.next(true);
            this.initialized$.complete();
          });
        });
      return this.initialized$;
    } else {
      throw new Error(
        // eslint-disable-next-line max-len
        'LaunchDarkly client side id is required to initialize the LaunchDarkly sdk. Make sure the environment config contains `launchdarkly.clientSideId`',
      );
    }
  }

  /**
   * Guarantees that launchdarkly is initialized before emitting flag value.
   * This is mostly useful for code which runs before the LaunchDarklyResolver
   * (ie in Angular modules and other early initialization code).
   */
  variation$<T>(flag: string, defaultValue: T): Observable<T> {
    return this
      .initialized$
      .pipe(
        first(initialized => initialized),
        map(() => this.ldclient.variation(flag, defaultValue)),
      );
  }

  /**
   * Returns a feature flag variation.
   *
   * In the client-side JavaScript SDKs, the underlying `ldclient.variation()` method is always a fast,
   * synchronous operation because all of the feature flag values for the current user
   * have already been loaded into memory.
   *
   * This synchronous method can be used by components since they only render after
   * the LaunchDarklyResolver ensures that the sdk is initialized.
   *
   * @param flag the name of the feature flag
   * @param defaultValue default value to use if the feature flag is not defined in launch darkly
   * @returns the feature flag value, or if undefined, the default value
   */
  variation<T>(flag: string, defaultValue: T): T {
    return this.ldclient.variation(flag, defaultValue);
  }

  private buildUser(profile: Profile): LDsdk.LDUser {
    const email = profile.identities.find(identity => identity.primary).email;
    const user: LDsdk.LDUser = {
      key: profile.id.toString(),
      firstName: profile.firstName,
      lastName: profile.lastName,
      email,
      anonymous: false,
      custom: {
        roles: profile.roles,
      },
    };
    return user;
  }
}
