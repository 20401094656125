import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationComponent } from './components/pagination/pagination.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TemplateModalRouteComponent } from './components/template-modal-route/template-modal-route.component';
import { TagsSelectFieldComponent } from './components/tags-select-field/tags-select-field.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TemplateModalDeleteComponent } from './components/template-modal-delete/template-modal-delete.component';
import { TemplatesTableComponent } from './components/templates-table/templates-table.component';
import { MomentModule } from 'ngx-moment';
import { RouterModule } from '@angular/router';
import { TemplateSearchFiltersComponent } from './components/template-search-filters/template-search-filters.component';
import { TagsListComponent } from './components/tags-list/tags-list.component';
import { TagsEditorComponent } from './components/tags-editor/tags-editor.component';
import { VariableAccordionComponent } from './components/variable-accordion/variable-accordion.component';
import { TemplateModalComponent } from './components/template-modal/template-modal.component';
import { QuillModule } from 'ngx-quill';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    NgSelectModule,
    MomentModule,
    RouterModule,
    QuillModule.forRoot(),
  ],
  declarations: [
    PaginationComponent,
    TemplateModalRouteComponent,
    TemplateModalComponent,
    TemplateModalDeleteComponent,
    TagsSelectFieldComponent,
    TemplatesTableComponent,
    TemplateSearchFiltersComponent,
    TagsListComponent,
    TagsEditorComponent,
    VariableAccordionComponent,
  ],
  exports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    NgSelectModule,
    PaginationComponent,
    TemplateModalRouteComponent,
    TemplateModalComponent,
    TemplateModalDeleteComponent,
    TagsSelectFieldComponent,
    TemplatesTableComponent,
    TagsEditorComponent,
    TemplateSearchFiltersComponent,
    VariableAccordionComponent,
  ],
})
export class SharedModule {}
