import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { ErrorHandlerService } from '@app/core';
import { StateEffects } from '@app/core/store/shared/state';
import { MessageTemplatesApiService } from '../shared/message-templates-api.service';
import { messageTemplatesActionNameTypeMap } from './message-templates.reducer';
import {
  MessageTemplateActionTypes,
  Search,
  SearchSuccess,
  PaginateSuccess,
  SearchError,
} from './message-templates.actions';
import { Template } from '@app/shared/models/template';
import { TemplateSearchService } from '@app/shared/services/template-search.service';

@Injectable()
export class MessageTemplatesEffects extends StateEffects<Template> {

  constructor(
    actions$: Actions,
    protected templatesApi: MessageTemplatesApiService,
    protected errorHandler: ErrorHandlerService,
    protected searchService: TemplateSearchService,
  ) {
    super(
      actions$,
      templatesApi,
      messageTemplatesActionNameTypeMap,
      errorHandler
    );
  }

  @Effect()
  createMessageTemplates$ = this.createEntity$;

  @Effect()
  loadMessageTemplatess$ = this.loadEntities$;

  @Effect()
  getMessageTemplatesById$ = this.getEntityById$;

  @Effect()
  updateMessageTemplates$ = this.updateEntity$;

  @Effect()
  deleteMessageTemplates$ = this.deleteEntity$;

  @Effect()
  search$: Observable<Action> = this
    .actions$
    .pipe(
      ofType<Search>(MessageTemplateActionTypes.SEARCH),
      map(action => action.payload),
      switchMap(criteria => this.searchService.search(criteria)),
      map(result => new SearchSuccess(result)),
      catchError(err => of(new SearchError(this.errorHandler.handleErrorSafe(err))))
    );

  @Effect()
  paginate$: Observable<Action> = this
    .actions$
    .pipe(
      ofType<Search>(MessageTemplateActionTypes.PAGINATE),
      map(action => action.payload),
      switchMap(criteria => this.searchService.search(criteria)),
      map(result => new PaginateSuccess(result))
    )
}
