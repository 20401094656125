import { Component, Input, EventEmitter, Output, Injector } from '@angular/core';
import { TemplateService } from '@app/shared/services/template.service';
import { TemplateModalService } from '@app/shared/services/template-modal.service';
import { Observable } from 'rxjs/internal/Observable';
import { Template } from '@app/shared/models/template';

@Component({
  selector: 'omg-templates-table',
  templateUrl: './templates-table.component.html',
  styleUrls: ['./templates-table.component.scss'],
})
export class TemplatesTableComponent {

  @Input() templates: Template[];
  @Input() loading = false;

  @Output()
  tagClick: EventEmitter<string> = new EventEmitter();

  headerRowShadow = false;

  constructor(
    public templateService: TemplateService,
    private modalService: TemplateModalService,
    private injector: Injector,
  ) {}

  isEditable(template: Template): Observable<boolean> {
    return this.templateService.isEditable(template);
  }

  onScroll(event: Event): void {
    const el = event.target as HTMLDivElement;
    this.headerRowShadow = el.scrollTop > 0;
  }

  isDeletable(template: Template): Observable<boolean> {
    return this.templateService.isDeletable(template);
  }

  delete(template: Template): void {
    this.modalService.openTemplateDeleteModal(template, this.injector);
  }
}
