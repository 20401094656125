import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/api/api.service';
import { Tag } from './tag.type';
import { EntityApiService } from '@app/core/store/shared/entity-api-service';

// includes getById(), getAll(), update(), delete(), and save() by default
@Injectable({
  providedIn: 'root',
})
export class TagApiService extends EntityApiService<Tag> {
  constructor(api: ApiService) {
    super(
      api, {
        basePath: '/v2/admin/tags',
        params: null,
      });
  }
}
