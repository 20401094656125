import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { AuthService } from '@auth0/auth0-angular';
import { ProfileSelectors } from '@app/core/profile';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'omg-app-navbar',
  templateUrl: './app-navbar.component.html',
  styleUrls: ['./app-navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppNavbarComponent implements OnInit {

  urls = {
    admin: environment.links.admin,
    tasks: environment.links.tasks,
    appointmentScanner: environment.links.appointmentScanner,
    unregisteredPatients: environment.links.unregisteredPatients,
    virtualVisits: environment.links.virtualVisits,
    docs: environment.links.docs,
    faxes: environment.links.faxes,
    contacts: environment.links.contacts,
    providers: environment.links.providers,
    myAccount: environment.links.myAccount,
  };

  userName$: Observable<string>;
  appointmentsUrl$: Observable<string>;
  providerScheduleUrl$: Observable<string>;
  walkInVisitsUrl$: Observable<string>;
  officeScheduleUrl$: Observable<string>;

  constructor(
    private authService: AuthService,
    private profileSelectors: ProfileSelectors,
  ) {}

  ngOnInit(): void {
    this.userName$ = this.profileSelectors.primaryIdentityName;
    this.providerScheduleUrl$ = this
      .profileSelectors
      .profileId
      .pipe(
        map(id => environment.linkTemplates.providerSchedule.replace('{USER_ID}', id.toString())),
      );
    this.walkInVisitsUrl$ = this
      .profileSelectors
      .office
      .pipe(
        map(office => environment.linkTemplates.walkInVisits.replace('{OFFICE_ID}', office.id.toString()).replace('{DATE}', this.date)),
      );
    this.appointmentsUrl$ = this
      .profileSelectors
      .office
      .pipe(
        map(office => environment.linkTemplates.appointments.replace('{OFFICE_ID}', office.id.toString()))
      );
    this.officeScheduleUrl$ = this
      .profileSelectors
      .office
      .pipe(
        map(office => environment.linkTemplates.officeSchedule.replace('{OFFICE_ID}', office.id.toString()).replace('{DATE}', this.date))
      );
  }

  logout(): void {
    const loginUrl = `${window.location.origin}/login`;
    this.authService.logout({ returnTo: loginUrl });
  }

  get date(): string {
    return new Date().toISOString().slice(0, 10);
  }
}
