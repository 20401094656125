/* eslint-disable no-console */
/* eslint-disable quotes */
import { Component, Input, OnInit } from "@angular/core";
import {
  faChevronUp,
  faChevronDown,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";

import { Profile } from "@app/core/profile";
import moment from "moment";

function getTimeZone(date: Date, timezone?: string): string {
  const timeZone = new Intl.DateTimeFormat("en-US", {
    timeZone: timezone,
    timeZoneName: "short",
  })
    .formatToParts(date)
    .find((part) => part.type === "timeZoneName");
  return timeZone ? timeZone.value : "";
}

@Component({
  selector: "omg-variable-accordion",
  templateUrl: "./variable-accordion.component.html",
  styleUrls: ["./variable-accordion.component.scss"],
})
export class VariableAccordionComponent implements OnInit {
  readonly chevronUpIcon: IconDefinition = faChevronUp;
  readonly chevronDownIcon: IconDefinition = faChevronDown;
  userDate: string;
  userTime: string;
  userFullname: string;
  userTitle: string;
  @Input() user: Profile;
  open = false;

  ngOnInit(): void {
    const now = new Date();
    this.userDate = moment().format("MM/DD/YYYY");
    this.userTime = `${moment().format("LT")} ${getTimeZone(now)}`;
    this.userFullname = `${this.user.firstName} ${this.user.lastName}`;
    this.userTitle = this.user.identities[0]?.name;
  }
  toggle(event: MouseEvent): void {
    event.preventDefault();
    this.open = !this.open;
  }
}
