import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from '@environments/environment';

import { appEffects } from './store/app-store.effects';
import { appReducer } from './store/app-store.reducer';
import { debugReducer } from './store/debug.reducer';

// Add ngrx console logging when in development mode
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const metaReducers: MetaReducer<any>[] = !environment.production
  ? [debugReducer]
  : [];

@NgModule({
  imports: [
    StoreModule.forRoot(appReducer, { metaReducers }),
    EffectsModule.forRoot(appEffects),
    // NOTE: The router store conflicts with the hybrid downgradeModule
    // This can be added back once the migration is completed.
    // AppStoreRouterModule,
    !environment.production ? StoreDevtoolsModule.instrument({}) : [],
  ],
})
export class AppStoreModule {}
