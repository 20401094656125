import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { ApiService } from '../../api';
import { mapProfileResponseToEntity } from './profile-api-mappers';
import { ProfileResponse } from './profile-response.type';

@Injectable()
export class ProfileApiService {
  private profilePath = '/v2/admin/profile';

  constructor(private apiService: ApiService) {}

  get() {
    return this.apiService
      .get<ProfileResponse>(this.profilePath)
      .pipe(map(mapProfileResponseToEntity));
  }
}
