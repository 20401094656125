import { Component, Input, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { faCheck, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Template } from '@app/shared/models/template';
import { TemplateContext } from '@app/shared/models/template-context';
import { TemplateContextToken } from '@app/shared/tokens/template-context.token';

@Component({
  selector: 'omg-template-modal-delete',
  templateUrl: './template-modal-delete.component.html',
  styleUrls: ['./template-modal-delete.component.scss'],
})
export class TemplateModalDeleteComponent {

  @Input() template: Template;
  checkIcon: IconDefinition = faCheck;

  constructor(
    private modal: NgbActiveModal,
    @Inject(TemplateContextToken) private context: TemplateContext,
  ) {}

  delete(): void {
    this.context.actions.delete(this.template);
    this.modal.close();
  }

  cancel(): void {
    this.modal.close();
  }
}
