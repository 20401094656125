import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { AppShellComponent } from './features/app-shell/app-shell.component';
import { LaunchDarklyResolver } from './core/launch-darkly/launchdarkly.resolver';
import { LoginComponent } from './core/login/login.component';
import { UnauthorizedComponent } from './core/unauthorized/unauthorized.component';
import { MessageTemplatesComponent } from './features/message-templates/components/message-templates/message-templates.component';
import { TextTemplatesComponent } from './features/text-templates/components/text-templates/text-templates.component';
import { UserProfileResolver } from './core/profile/shared/user-profile.resolver';
import { TemplateModalRouteComponent } from './shared/components/template-modal-route/template-modal-route.component';
import { TemplateTagsResolver } from './shared/resolvers/template-tags.resolver';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/templates/snippet',
  },
  {
    path: '',
    canActivate: [AuthGuard],
    resolve: {
      profile: UserProfileResolver,
      launchdarkly: LaunchDarklyResolver,
      tags: TemplateTagsResolver,
    },
    component: AppShellComponent,
    children: [
      {
        path: 'templates',
        children: [
          {
            path: 'message',
            component: MessageTemplatesComponent,
            children: [
              {
                path: ':id',
                component: TemplateModalRouteComponent,
              }
            ]
          },
          {
            path: 'snippet',
            component: TextTemplatesComponent,
            children: [
              {
                path: ':id',
                component: TemplateModalRouteComponent,
              }
            ]
          },
        ],
      },
    ],
  },
  {
    path: 'design',
    loadChildren: () => import('./features/design-guide/design-guide.module').then(m => m.DesignGuideModule),
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  declarations: [],
  providers: [LaunchDarklyResolver],
})
export class AppRoutingModule { }
