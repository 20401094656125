<div class="container h-100">
  <div class="row h-100">
    <div class="login-button col-md-3 ml-md-auto mr-md-auto">
      <button class=" align-middle btn btn-lg"
              variant="link"
              (click)="login()">Login with 1Life</button>
    </div>
  </div>

</div>
