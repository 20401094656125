<div class="template-table container"
     (scroll)="onScroll($event)">

  <div class="row row-header"
       [ngClass]="{
         'shadow': headerRowShadow
        }">
    <div class="col-5 col-name">Title</div>
    <div class="col-5 col-tags">Tags</div>
    <div class="col-1 col-purpose">Purpose</div>
    <div class="col-2 col-edited-on">Last Edited</div>
    <div class="col-3 col-edited-by">Editor</div>
  </div>

  <ng-container *ngIf="!loading; else loadingState">
    <div class="row row-body"
         *ngFor="let template of templates"
         data-cy="template-row"
         [attr.data-template-id]="template.id"
         [attr.data-template-type]="template.type"
         [attr.data-template-purpose]="template.purpose"
         [attr.data-template-name]="template.name">

      <div class="col-5 col-name">
        <span class="name truncate"
              data-cy="template-name">
          {{ template.name }}
        </span>
      </div>

      <div class="col-5 col-tags">
        <omg-tags-list *ngIf="template.tags"
                       [template]="template"
                       (tagClick)="tagClick.emit($event)">
        </omg-tags-list>
      </div>

      <div class="col-1 col-purpose text-capitalize"
           data-cy="template-purpose">
        {{ templateService.purposeDisplayValue(template) }}
      </div>

      <div class="col-2 col-edited-on">
        <time data-cy="template-updated-date">
          {{ template.updatedAt | amLocal | amDateFormat: 'MM/DD/YY' }}
        </time>
      </div>

      <div class="col-3 col-edited-by">
        <span class="editor truncate"
              data-cy="template-updated-by">
          {{ template.updatedBy }}
        </span>
        <div class="actions"
             data-cy="row-actions">
          <a *ngIf="isEditable(template) | async; else viewBtn"
             class="om-button -flat edit"
             [routerLink]="template.id"
             data-cy="edit-template-button">Edit</a>

          <ng-template #viewBtn>
            <a class="om-button -flat view"
               [routerLink]="template.id"
               data-cy="view-template-button">View</a>
          </ng-template>

          <button class="om-button -flat delete"
                  *ngIf="isDeletable(template) | async"
                  (click)="delete(template)">Delete</button>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #loadingState>
    <div class="row row-body"
         *ngFor="let i of ' '.repeat(20).split('')">
      <div class="col-5 col-name name truncate">
        <div class="content-loading-block"></div>
      </div>

      <div class="col-5 col-tags">
        <div class="content-loading-block"></div>
      </div>

      <div class="col-1 col-purpose text-capitalize">
        <div class="content-loading-block"></div>
      </div>

      <div class="col-2 col-edited-on">
        <div class="content-loading-block"></div>
      </div>

      <div class="col-3 col-edited-by">
        <div class="content-loading-block"></div>
      </div>
    </div>
  </ng-template>

</div>

<div class="empty-results"
     *ngIf="templates.length === 0 && !loading">
  <strong class="center">None of the templates matched this search.</strong>
  <p>Try another search, or click the arrow in the tag dropdown to find a template by tag.</p>
</div>
