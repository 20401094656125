<div class="accordion-container">
  <button class="om-button -invisible" (click)="toggle($event)">
    <fa-icon *ngIf="!open" [icon]="chevronDownIcon"></fa-icon>
    <fa-icon *ngIf="open" [icon]="chevronUpIcon"></fa-icon>
    <span class="variables-header-text">Variables</span>
  </button>
  <div class="column-container" *ngIf="open">
    <span class="subtext">
      Variables autofill with specified values when a template is inserted into
      a chart.
    </span>
    <div class="variable-list">
      <ul class="column">
        <li class="list-item">
          <span class="variable-name">.user_time</span>
          <div class="tooltiptext">{{ userTime }}</div>
        </li>
        <li class="variable-description">time based on the user's computer</li>

        <li class="list-item">
          <span class="variable-name">.user_date</span>
          <div class="tooltiptext">{{ userDate }}</div>
        </li>
        <li class="variable-description">date based on the user's computer</li>

        <li class="list-item">
          <span class="variable-name">.patient_time</span>
          <div class="tooltiptext">ex. 3:17 PM HST</div>
        </li>
        <li class="variable-description">
          time based on the timezone of the patient's service area
        </li>

        <li class="list-item">
          <span class="variable-name">.patient_date</span>
          <div class="tooltiptext">ex. 05/11/2023</div>
        </li>
        <li class="variable-description">
          date based on the timezone of the patient's service area
        </li>

        <li class="list-item">
          <span class="variable-name">.patient_preferred_name</span>
          <div class="tooltiptext">ex. Homie</div>
        </li>
        <li class="variable-description">patient's preferred name</li>

        <li class="list-item">
          <span class="variable-name">.patient_fullname</span>
          <div class="tooltiptext">ex. Homer Simpson</div>
        </li>
        <li class="variable-description">patient's first and last name</li>

        <li class="list-item">
          <span class="variable-name">.patient_lastname</span>
          <div class="tooltiptext">ex. Simpson</div>
        </li>
        <li class="variable-description">patient's last name</li>

        <li class="list-item">
          <span class="variable-name">.patient_firstname</span>
          <div class="tooltiptext">ex. Homer</div>
        </li>
        <li class="variable-description">patient's first name</li>

        <li class="list-item">
          <span class="variable-name">.user_firstname</span>
          <div class="tooltiptext">{{ user.firstName }}</div>
        </li>
        <li class="variable-description">user's first name</li>

        <li class="list-item">
          <span class="variable-name">.user_fullname</span>
          <div class="tooltiptext">{{ userFullname }}</div>
        </li>
        <li class="variable-description">user's full name</li>

        <li class="list-item">
          <span class="variable-name">.user_npi</span>
          <div class="tooltiptext">{{ user.npi }}</div>
        </li>
        <li class="variable-description">user's NPI</li>

        <li class="list-item">
          <span class="variable-name">.user_role</span>
          <div class="tooltiptext">{{ user.roles[0] }}</div>
        </li>
        <li class="variable-description">
          role at One Medical e.g. nurse practitioner
        </li>

        <li class="list-item">
          <span class="variable-name">.user_title</span>
          <div class="tooltiptext">{{ userTitle }}</div>
        </li>
        <li class="variable-description">
          user's first and last name and suffix
        </li>

        <li class="list-item">
          <span class="variable-name">.patient_pcp</span>
          <div class="tooltiptext">ex. Julius Hibbert, M.D</div>
        </li>
        <li class="variable-description">
          if patient does not have a PCP then it will say 'one of our primary
          care providers'
        </li>

        <li class="list-item">
          <span class="variable-name">.patient_office</span>
          <div class="tooltiptext">ex. Springfield</div>
        </li>
        <li class="variable-description">patient's office</li>
      </ul>
    </div>
  </div>
</div>
