import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/api/api.service';
import { EntityApiService } from '@app/core/store/shared/entity-api-service';
import { Template } from '@app/shared/models/template';

// includes getById(), getAll(), update(), delete(), and save() by default
@Injectable({
  providedIn: 'root'
})
export class MessageTemplatesApiService extends EntityApiService<Template> {
  constructor(api: ApiService) {
    super(api, {
      basePath: '/v2/admin/message_templates',
      params: null
    });
  }
}
