import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ApiService } from '@app/core';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';

import { TagApiService } from '../shared/tag-api.service';
import { TagActions } from './tag.actions';
import { TagEffects } from './tag.effects';
import { tagActionNameTypeMap, TagReducerToken, tagStateConfig, tagReducerFactory } from './tag.reducer';
import { TagSelectors } from './tag.selectors';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(tagStateConfig.statePath, TagReducerToken),
    EffectsModule.forFeature([TagEffects]),
  ],
  providers: [
    {
      provide: TagReducerToken,
      useFactory: tagReducerFactory,
    },
    {
      provide: TagSelectors,
      useFactory: store => new TagSelectors(store, tagStateConfig),
      deps: [Store],
    },
    {
      provide: TagActions,
      useFactory: store => new TagActions(store, tagActionNameTypeMap),
      deps: [Store],
    },
    {
      provide: TagApiService,
      useFactory: (apiService) =>
        new TagApiService(apiService),
      deps: [ApiService],
    },
  ],
})
export class TagStoreModule { }
