import {
  createFeatureSelector,
  createSelector,
  select,
  Store,
} from '@ngrx/store';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { StoreEntityState } from '@app/core/store/shared/state';
import { MessageTemplatesState } from './message-templates.reducer';
import { SearchFilters } from '@app/shared/models/search-filters';
import { PaginationMetadata } from '@app/shared/models/pagination-metadata';
import { Template } from '@app/shared/models/template';

export class MessageTemplatesSelectors {

  protected adapter: EntityAdapter<Template> = createEntityAdapter<Template>();

  protected selectState = createFeatureSelector<MessageTemplatesState>('messageTemplates');

  protected selectTemplates = createSelector(this.selectState, (state) => state.templates);

  protected selectPagination = createSelector(this.selectState, (state) => {
    const pagination: PaginationMetadata = {
      ...state.pagination,
    };
    return pagination;
  });

  protected selectError = createSelector(this.selectState, (state) => {
    return state.error;
  });

  protected selectLoading = createSelector(this.selectState, (state) => {
    return state.loading;
  });

  protected selectFilters = createSelector(this.selectState, (state) => {
    const filters: SearchFilters = {
      ...state.filters
    };
    return filters;
  });

  protected selectEntities = createSelector(
    this.selectState,
    this.adapter.getSelectors().selectAll
  );

  protected selectEntitiesDictionary = createSelector(this.selectState, this.adapter.getSelectors().selectEntities);

  protected selectEntity = createSelector(this.selectEntitiesDictionary, (entities, { id }) => entities[id]);

  constructor(private store: Store<Template>) { }

  get state(): Observable<StoreEntityState<Template>> {
    return this.store.pipe(select(this.selectState));
  }

  get entities(): Observable<Template[]> {
    return this.store.pipe(select(this.selectEntities));
  }

  get templates(): Observable<Template[]> {
    return this.store.pipe(select(this.selectTemplates));
  }

  get filters(): Observable<SearchFilters> {
    return this.store.pipe(select(this.selectFilters));
  }

  get pagination(): Observable<PaginationMetadata> {
    return this.store.pipe(select(this.selectPagination));
  }

  get loading(): Observable<boolean> {
    return this.store.pipe(select(this.selectLoading));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get error(): Observable<any> {
    return this.store.pipe(select(this.selectError));
  }

  getById(id: number): Observable<Template> {
    return this.store.pipe(select(this.selectEntity, { id }));
  }

  filter(predicate: (entity: Template) => boolean): Observable<Template[]> {
    return this.entities.pipe(
      map((entities: Template[]) => entities.filter(predicate))
    );
  }
}
