import { FactoryProvider, PLATFORM_ID } from '@angular/core';
import { WindowToken } from '@app/shared/tokens/window.token';
import { isPlatformBrowser } from '@angular/common';

export const WindowProvider: FactoryProvider = {
  provide: WindowToken,
  useFactory: (platformId: object): Window => {
    return isPlatformBrowser(platformId) ? window : {} as Window;
  },
  deps: [PLATFORM_ID],
};
