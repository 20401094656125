import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiConfigOptions } from '@app/core/api/api.type';
import { camelCase, snakeCase } from '@app/utils';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';

const baseUrl = environment.apiServer;

@Injectable()
export class ApiService {
  constructor(private http: HttpClient) { }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get<T>(endpoint: string, params?: any, autoCase?: boolean): Observable<T> {
    const response$ = this.http.get<T>(`${baseUrl}${endpoint}`, { params });
    return autoCase ? this.camelize<T>(response$) : response$;
  }

  save<T>(
    endpoint: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any,
    config?: ApiConfigOptions,
    autoCase?: boolean,
  ) {
    if (autoCase) {
      data = snakeCase(data);
    }
    const response$ = this.http.post<T>(`${baseUrl}${endpoint}`, data, config);
    return autoCase ? this.camelize<T>(response$) : response$;
  }

  update<T>(
    endpoint: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any,
    config?: ApiConfigOptions,
    autoCase?: boolean,
  ) {
    if (autoCase) {
      data = snakeCase(data);
    }
    const response$ = this.http.put<T>(`${baseUrl}${endpoint}`, data, config);
    return autoCase ? this.camelize<T>(response$) : response$;
  }

  delete<T>(endpoint: string, config?: ApiConfigOptions) {
    return this.http.delete<T>(`${baseUrl}${endpoint}`, config);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private camelize<T>(response$: Observable<any>) {
    return response$.pipe(map(response => camelCase(response) as T));
  }
}
