import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { TemplateModalService } from '@app/shared/services/template-modal.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'omg-template-modal-route',
  template: '',
})
export class TemplateModalRouteComponent implements OnInit, OnDestroy {

  private modal: NgbModalRef;

  constructor(
    private modalService: TemplateModalService,
    private router: Router,
    private route: ActivatedRoute,
    private injector: Injector,
  ) {}

  ngOnInit(): void {
    const param = this.route.snapshot.paramMap.get('id');
    const id = parseInt(param, 10);
    this.openTemplate(id);
  }

  ngOnDestroy(): void {
    if (this.modal) {
      this.modal.close();
    }
  }

  private openTemplate(id: number): void {
    // open template modal
    // store reference to the modal so we can close it on route changes
    // register callbacks to be invoked when the user closes/dismisses the modal
    this
      .modalService
      .openTemplate(id, this.injector)
      .toPromise()
      .then(modal => {
        this.modal = modal;
        return modal.result;
      })
      .then(
        () => this.onTemplateDismissed(), // on close
        () => this.onTemplateDismissed(), // on dismiss
      );
  }

  private onTemplateDismissed(): void {
    // route the user back to url without the template id
    this.router.navigate(['..'], { relativeTo: this.route });
  }

}
