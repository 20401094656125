import { FactoryProvider } from '@angular/core';
import { TemplateContextToken } from '@app/shared/tokens/template-context.token';
import { TextTemplatesActions, TextTemplateActionTypes } from '@app/features/text-templates/store/text-templates.actions';
import { TextTemplatesSelectors } from '@app/features/text-templates/store/text-templates.selectors';
import { TemplateContext } from '@app/shared/models/template-context';
import { MessageTemplatesSelectors } from '@app/features/message-templates/store/message-templates.selectors';
import { MessageTemplatesActions, MessageTemplateActionTypes } from '@app/features/message-templates/store/message-templates.actions';

export const TextTemplateContextProvider: FactoryProvider = {
  provide: TemplateContextToken,
  useFactory: (actions: TextTemplatesActions, selectors: TextTemplatesSelectors): TemplateContext => {
    return {
      actions,
      selectors,
      SEARCH_SUCCESS: TextTemplateActionTypes.SEARCH_SUCCESS,
      type: 'text',
      index: 'text_templates',
    };
  },
  deps: [
    TextTemplatesActions,
    TextTemplatesSelectors,
  ]
};

export const MessageTemplateContextProvider: FactoryProvider = {
  provide: TemplateContextToken,
  useFactory: (actions: MessageTemplatesActions, selectors: MessageTemplatesSelectors): TemplateContext => {
    return {
      actions,
      selectors,
      SEARCH_SUCCESS: MessageTemplateActionTypes.SEARCH_SUCCESS,
      type: 'message',
      index: 'message_templates',
    };
  },
  deps: [
    MessageTemplatesActions,
    MessageTemplatesSelectors,
  ]
};
