import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { ErrorHandlerService } from '@app/core';
import { StateEffects } from '@app/core/store/shared/state';
import { TagApiService } from '../shared/tag-api.service';
import { Tag } from '../shared/tag.type';
import { tagActionNameTypeMap } from './tag.reducer';

@Injectable()
export class TagEffects extends StateEffects<Tag> {
  constructor(
    actions$: Actions,
    protected tagApi: TagApiService,
    protected errorHandler: ErrorHandlerService,
  ) {
    super(
      actions$,
      tagApi,
      tagActionNameTypeMap,
      errorHandler,
    );
  }

  @Effect()
  createTag$ = this.createEntity$;

  @Effect()
  loadTags$ = this.loadEntities$;

  @Effect()
  getTagById$ = this.getEntityById$;

  @Effect()
  updateTag$ = this.updateEntity$;

  @Effect()
  deleteTag$ = this.deleteEntity$;
}
