<div class="modal-header template-modal-header d-flex justify-content-between">
    <h3>
        Are you sure?
    </h3>
    <button class="om-icon icon-close"
            type="button"
            (click)="cancel()">
    </button>
</div>

<div class="modal-body">
    <h6>Deleting a template cannot be undone.</h6>
    <ul class="list-unstyled">
        <li>
            <fa-icon [icon]="checkIcon"></fa-icon>
            <span>All share links will be inaccessible.</span>
        </li>
        <li>
            <fa-icon [icon]="checkIcon"></fa-icon>
            <span>The template will be inaccessible from the insertion tool.</span>
        </li>
        <li>
            <fa-icon [icon]="checkIcon"></fa-icon>
            <span>The template will be permanently removed from the system.</span>
        </li>
    </ul>
</div>

<div class="modal-footer action-bar d-flex justify-content-start">
    <button class="om-button -primary"
            type="button"
            (click)="delete()">
        Delete Permanently
    </button>
    <button class="om-button -flat"
            type="button"
            (click)="cancel()">
        Cancel
    </button>
</div>
