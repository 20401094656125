import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { TemplateModalService } from '@app/shared/services/template-modal.service';
import { MessageTemplateContextProvider, TextTemplateContextProvider } from '@app/shared/providers/template-context.provider';

@Component({
  selector: 'omg-app-shell',
  templateUrl: 'app-shell.component.html',
  styleUrls: ['./app-shell.component.scss'],
})
export class AppShellComponent implements OnInit, OnDestroy {

  tab: string;

  private subscriptions: Subscription = new Subscription();
  private textTemplateInjector: Injector;
  private messageTemplateInjector: Injector;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: TemplateModalService,
    private injector: Injector,
  ) {}

  ngOnInit(): void {

    this.initInjectors();

    // initialize the active tab
    this.updateTab();

    // update the active tab whenever the route changes
    this.subscriptions.add(this
      .router
      .events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => this.updateTab())
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  openCreateTextTemplate(): void {
    this.subscriptions.add(this
      .modalService
      .openCreateTemplateModal(this.textTemplateInjector)
      .subscribe()
    );
  }

  openCreateMessageTemplate(): void {
    this.subscriptions.add(this
      .modalService
      .openCreateTemplateModal(this.messageTemplateInjector)
      .subscribe()
    );
  }

  private initInjectors(): void {
    this.textTemplateInjector = Injector.create({
      parent: this.injector,
      providers: [ TextTemplateContextProvider ],
    });
    this.messageTemplateInjector = Injector.create({
      parent: this.injector,
      providers: [ MessageTemplateContextProvider ],
    });
  }

  private updateTab(): void {
    if (this.route.firstChild.snapshot.url[0].path === 'templates') {
      this.tab = this.route.firstChild.firstChild.snapshot.url[0].path;
    }
  }
}
