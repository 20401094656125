import { ErrorHandler, Injectable } from '@angular/core';

import { LoggerService } from '@app/core/logger';
import { isPlainObject } from '@app/utils';

import { getErrorMessage } from './error-utils';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService implements ErrorHandler {
  constructor(
    private logger: LoggerService,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleError(err: any) {
    this.logError(err);
    return err;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleErrorSafe(err: any) {
    this.logError(err);

    if (isPlainObject(err)) {
      return err;
    } else {
      return getErrorMessage(err);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private logError(err: any) {
    this.logger.error(err);
  }
}
